import React, { useEffect, useRef, useState } from 'react';
import './FarmDialog.scss';
import Dialog from '@mui/material/Dialog';
import { FarmInfo, WalletType } from '../code/types';
import { Alert, Button, FormControlLabel, IconButton, Radio, RadioGroup, Switch, TextField, Typography } from '@mui/material';
import { ethers } from 'ethers';
import { GiddyChef } from '../code/GiddyChef';
import { LoadingButton } from '@mui/lab';
import { formatTokenValue, parseBigNumber } from '../code/globals';
import { getErc20TokenAllowance, getErc20TokenBalance } from '../code/Erc20';
import ArrowSquare from '../content/arrow-up-right-from-square.svg';
import { appConfig, totalFarmPoints } from '../code/appConfig';
import CloseIcon from 'mdi-react/CloseIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import Cactus from '../content/cactus.svg';

interface FarmDialogProps {
  isOpen: boolean;
  giddyChef: GiddyChef;
  farm: FarmInfo;
  account: string;
  onClose(message:string): void;
}

export default function FarmDialog({isOpen, giddyChef, farm, account, onClose}:FarmDialogProps) {
  const [showStake, setShowStake] = useState(true);
  const [farmError, setFarmError] = useState('');
  const [farmSuccess, setFarmSuccess] = useState('');
  const [loading, setLoading] = useState(true);
  const [farmBalance, setFarmBalance] = useState(ethers.constants.MaxUint256);
  const [allowance, setAllowance] = useState(ethers.constants.Zero);
  const [available, setAvailable] = useState(ethers.constants.Zero);
  const [staked, setStaked] = useState(ethers.constants.Zero);
  const [rewards, setRewards] = useState(ethers.constants.Zero);
  const [depositError, setDepositError] = useState('');
  const [depositAmount, setDepositAmount] = useState('');
  const [approveDisabled, setApproveDisabled] = useState(true);
  const [approveText, setApproveText] = useState('');
  const [stakeLegacy, setStakeLegacy] = useState(false);
  const [stakeDisabled, setStakeDisabled] = useState(true);
  const [stakeText, setStakeText] = useState('');
  const [giddyPerDay, setGiddyPerDay] = useState(ethers.constants.Zero);
  const [withdrawError, setWithdrawError] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [unstakeDisabled, setUnstakeDisabled] = useState(true);
  const [unstakeText, setUnstakeText] = useState('');
  const signatureResult = useRef(null as any);

  useEffect(() => {
    if (isOpen) {
      setShowStake(false);
      setLoading(true);
      setFarmBalance(ethers.constants.MaxUint256);
      setAllowance(ethers.constants.Zero);
      setAvailable(ethers.constants.MaxUint256);
      setStaked(ethers.constants.MaxUint256);
      setRewards(ethers.constants.MaxUint256);
      setGiddyPerDay(ethers.constants.Zero);
      setDepositError('');
      setDepositAmount('');
      setWithdrawError('');
      setWithdrawAmount('');
      setFarmError('');
      setFarmSuccess('');

      setApproveDisabled(true);
      setApproveText('Approve');
      setStakeDisabled(true);
      setStakeText('Stake');
      setUnstakeDisabled(true);
      setUnstakeText('Unstake');

      const loadData = async () => {
        if (farm && account) {
          const results = await Promise.all([
            getErc20TokenAllowance(farm.address, account, appConfig.giddyChefAddress),
            getErc20TokenBalance(farm.address, appConfig.giddyChefAddress),
            getErc20TokenBalance(farm.address, account),
            giddyChef.stakedGiddy(farm.pid, account),
            giddyChef.pendingGiddy(farm.pid, account),
          ]);
          setAllowance(results[0]);
          setFarmBalance(results[1]);
          setAvailable(results[2]);
          setStaked(results[3]);
          setRewards(results[4]);
          setLoading(false);
        }
      }
      loadData();
    }
  }, [isOpen, farm, giddyChef, account]);

  useEffect(() => {
    try {
      setGiddyPerDay(ethers.constants.Zero);
      if (depositAmount && farm && !farmBalance.eq(ethers.constants.MaxUint256)) {
        const amount = parseBigNumber(depositAmount, farm.decimals);
        const poolTotal = amount.add(farmBalance);
        if (amount.gt(0) && poolTotal.gt(0)) {
          const farmPerDay = appConfig.giddyPerSecond.mul(86400).mul(farm.points).div(totalFarmPoints);
          setGiddyPerDay(farmPerDay.mul(amount).div(poolTotal));
        }
      }
    }
    catch (error: any) {
      console.error('GIDDY.giddyPerDay', error);
    }
  }, [depositAmount, giddyChef, farm, farmBalance]);

  function onRadioChange(event: any) {
    setShowStake(event.target.value === 'stake');
  }

  function depositChange(value: string) {
    setDepositError('');
    setFarmError('');
    setFarmSuccess('');
    setApproveDisabled(true);
    setApproveText('Approve');
    setStakeDisabled(true);
    setStakeText('Stake');

    let allowanceAmount = allowance;
    if (signatureResult.current !== null) {
      allowanceAmount = ethers.constants.Zero;
      setAllowance(allowanceAmount);
      signatureResult.current = null;
    }

    value = value.trim().replaceAll(',', '');
    if (value.startsWith('.')) value = '0' + value;
    setDepositAmount(value);
    if (value.length > 0) {
      const amount = parseBigNumber(value, farm.decimals);
      if (!amount.gt(ethers.constants.Zero)) {
        setDepositError('Invalid Value');
      }
      else {
        if (amount.gt(available)) {
          setDepositError("Amount exceeds funds available");
        }
        else {
          if (amount.gt(allowanceAmount)) {
            setApproveDisabled(false);
          }
          else {
            setApproveText('Approved for ' + formatTokenValue(allowanceAmount, farm.decimals));
            setStakeDisabled(false);
          }
        }
      }
    }
  }

  function depositMaxClicked() {
    depositChange(formatTokenValue(available, farm.decimals, false));
  }

  async function aprroveClicked() {
    setFarmError('');
    setFarmSuccess('');
    if (!account) {
      setFarmError('Wallet account not found');
    }
    else {
      const amount = parseBigNumber(depositAmount, farm.decimals);
      if (!amount.gt(ethers.constants.Zero)) {
        setFarmError('Invalid amount');
      }
      else
      {
        if (amount.gt(allowance)) {
          setLoading(true);
          setApproveText('Confirming');
          if (farm.pid === 0 && giddyChef.walletType === WalletType.MetaMask && !stakeLegacy) {
            signatureResult.current = await giddyChef.giddySignature(amount, allowance);
            if (signatureResult.current[0]) {
              setFarmError(signatureResult.current[0]);
              setApproveText('Approve');
            }
            else {
              setAllowance(amount);
              setApproveText('Approved for ' + formatTokenValue(amount, farm.decimals));
              setApproveDisabled(true);
              setStakeDisabled(false);
              setFarmSuccess('Your deposit amount has been approved. Stake to complete your transaction.');
            }
          }
          else {
            const approveResult = await giddyChef.approve(farm.address, amount);
            if (!ethers.utils.isHexString(approveResult)) {
              setFarmError(approveResult);
              setApproveText('Approve');
            }
            else {
              setApproveText('Pending');
              const approveSuccess = await giddyChef.waitForTransactionStatus(approveResult, 1, 1000, 300);
              if (!approveSuccess) {
                setApproveText('Approve');
                setFarmError('Approval unsuccessful, transaction failed');
              }
              else {
                setAllowance(amount);
                setApproveText('Approved for ' + formatTokenValue(amount, farm.decimals));
                setApproveDisabled(true);
                setStakeDisabled(false);
                setFarmSuccess('Your deposit amount has been approved. Stake to complete your transaction.');
              }
            }
          }
          setLoading(false);
        } 
      }
    }
  }

  async function stakeClicked() {
    setFarmError('');
    setFarmSuccess('');
    if (!account) {
      setFarmError('Wallet account not found');
    }
    else {
      const amount = parseBigNumber(depositAmount, farm.decimals);
      if (!amount.gt(ethers.constants.Zero)) {
        setFarmError('Invalid amount');
      }
      else
      {
        setLoading(true);
        setStakeText('Confirming');

        const signature = signatureResult.current ? signatureResult.current[1] : null;
        const approvalRequest = signatureResult.current ? signatureResult.current[2] : null;
        const depositResult = await giddyChef.deposit(farm.pid, amount, signature, approvalRequest);
        if (!ethers.utils.isHexString(depositResult)) {
          setStakeText('Stake');
          setFarmError(depositResult);
        }
        else {
          setStakeText('Pending');
          const depositSuccess = await giddyChef.waitForTransactionStatus(depositResult, 1, 1000, 60);
          if (!depositSuccess) {
            setStakeText('Stake');
            setFarmError('Stake unsuccessful, transaction failed');
          }
          else {
            onClose('Giddy Up! Successfully staked ' + formatTokenValue(amount, farm.decimals) + ' ' + farm.symbol);
          }
        }

        setLoading(false);
      }
    }
  }

  function withdrawChange(value: string) {
    setWithdrawError('');
    setFarmError('');
    setFarmSuccess('');
    setUnstakeDisabled(true);
    setUnstakeText('Unstake');
    value = value.trim().replaceAll(',', '');
    if (value.startsWith('.')) value = '0' + value;
    setWithdrawAmount(value);
    if (value.length > 0) {
      const amount = parseBigNumber(value, farm.decimals);
      if (!amount.gt(ethers.constants.Zero)) {
        setWithdrawError('Invalid Value');
      }
      else {
        if (amount.gt(staked)) {
          setWithdrawError("Amount exceeds funds available");
        }
        else {
          setUnstakeDisabled(false);
        }
      }
    }
  }

  function withdrawMaxClicked() {
    withdrawChange(formatTokenValue(staked, farm.decimals, false));
  }

  async function unstakeClicked() {
    if (!giddyChef) {
      setFarmError('Not connected to wallet');
    }
    else {
      if (!account) {
        setFarmError('Wallet account not found');
      }
      else {
        const amount = parseBigNumber(withdrawAmount, farm.decimals);
        if (!amount.gt(ethers.constants.Zero)) {
          setFarmError('Invalid amount');
        }
        else
        {
          setLoading(true);
          setUnstakeText('Confirming');
          const withdrawResult = await giddyChef.withdraw(farm.pid, amount);
          if (!ethers.utils.isHexString(withdrawResult)) {
            setUnstakeText('Unstake');
            setFarmError(withdrawResult);
          }
          else {
            setUnstakeText('Pending');
            const unstakeSuccess = await giddyChef.waitForTransactionStatus(withdrawResult, 1, 1000, 300);
            if (!unstakeSuccess) {
              setUnstakeText('Unstake');
              setFarmError('Unstake unsuccessful, transaction failed');
            }
            else {
              onClose('Successfully unstaked ' + formatTokenValue(amount, farm.decimals) + ' ' + farm.symbol);
            }
          }
          setLoading(false);
        }
      }
    }
  }

  function openExchange() {
    if (farm.index === 1) {
      window.open('https://app.sushi.com/legacy/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x67eB41A14C0fe5CD701FC9d5A3D6597A72F641a6', '_blank');
    } else {
      window.open('https://app.sushi.com/swap?tokens=MATIC&tokens=' + farm.address + '&chainId=137', '_blank');
    }
  }

  let stakeBox = (
    <div className='stake-box'>
      { (farm.pid === 0 && giddyChef.walletType === WalletType.MetaMask) &&
      <FormControlLabel 
        control={
        <Switch size="small" 
          checked={stakeLegacy} 
          onClick={() => {setStakeLegacy(!stakeLegacy)}} />
        } 
        label={
          <Typography variant="caption">Enable legacy staking</Typography>
        }
      />
      }
      <div className='amount-box'>
        <TextField
          fullWidth
          label="Amount"
          value={depositAmount}
          onChange={(e) => depositChange(e.target.value)}
          error={depositError.length > 0}
          helperText={depositError}
          disabled={loading}
        />
        <Button sx={{height: 56}} onClick={depositMaxClicked} disabled={loading}>Max</Button>
      </div>
      <p className="giddy-rate">Current interest rate on amount above would yield {formatTokenValue(giddyPerDay, 18)} Giddy per day. Subject to change with market trends.</p>
      <LoadingButton 
        variant="contained" 
        fullWidth 
        disabled={approveDisabled}
        loading={loading && !approveDisabled}
        loadingPosition="end"
        endIcon={approveText.startsWith('Approved') ? <CheckIcon/> : undefined}
        onClick={aprroveClicked}
        sx={{
          ':disabled': {
            'background': '#E2DFD9',
            'color': '#FFF'
          }
        }}
        >
        { approveText }
      </LoadingButton>
      <LoadingButton 
        variant="contained" 
        fullWidth 
        disabled={stakeDisabled}
        loading={loading && !stakeDisabled}
        loadingPosition="end"
        onClick={stakeClicked}
        sx={{
          ':disabled': {
            'background': '#E2DFD9',
            'color': '#FFF'
          }
        }}
        >
        { stakeText }
      </LoadingButton>
    </div>
  );

  if (!showStake) {
    stakeBox = (
      <div className='stake-box'>
        <div className='amount-box'>
          <TextField
            fullWidth
            label="Amount" 
            value={withdrawAmount} 
            onChange={(e) => withdrawChange(e.target.value)} 
            error={withdrawError.length > 0} 
            helperText={withdrawError} />
          <Button sx={{height: 56}} onClick={withdrawMaxClicked}>Max</Button>
        </div>
        <LoadingButton 
          variant="contained" 
          fullWidth 
          disabled={unstakeDisabled}
          loading={loading}
          loadingPosition="end"
          onClick={unstakeClicked}
          sx={{
            ':disabled': {
              'background': '#E2DFD9',
              'color': '#FFF'
            }
          }}
          >
          { unstakeText }
        </LoadingButton>
      </div>
    );
  }

  return (
    <Dialog fullWidth maxWidth="xs" onClose={() => { onClose(''); }} open={isOpen}>
      <IconButton onClick={() => { onClose(''); }} sx={{ position: 'absolute', right: 8, top: 8, }}>
        <CloseIcon />
      </IconButton>
      <div className="farm-dialog">
        <div>
          <a href={'https://polygonscan.com/address/' + farm.address} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer">
            <span className="farm-symbol">{farm.symbol} - </span>
            <span className="farm-name">{farm.name}</span>
          </a>
        </div>
        <div className="user-box">
          <div className="user-box-text">
            <div>Available in Wallet: {formatTokenValue(available, farm.decimals)}</div>
            <div>Staked: {formatTokenValue(staked, farm.decimals)}</div>
            <div>Earned: {formatTokenValue(rewards, 18)}</div>
          </div>
          <div className='user-image-box'>
            <Cactus />
          </div>
        </div>
        {stakeBox}
        <Button onClick={openExchange} variant="text" sx={{ fontSize: "14px", color: "#7E7467", font: "Poppins", paddingLeft: "0", paddingRight: "0" }} endIcon={<ArrowSquare />}>Get {farm.name}</Button>
        {farmError && <div className="alert-box"><Alert severity="error">{farmError}</Alert></div>}
        {farmSuccess && <div className="alert-box"><Alert severity="success">{farmSuccess}</Alert></div>}
      </div>
    </Dialog>
  );
}
