import React from "react";
import Button from "@mui/material/Button";
import { appConfig } from "../code/appConfig";
import "./TokenAddress.scss";

export default function TokenAddress() {
  const tokenAddress = appConfig.farms[0].address;
  const polygonUrl = `https://polygonscan.com/token/${tokenAddress}`;
  const exchangeUrl = `https://app.sushi.com/swap?tokens=MATIC&tokens=${tokenAddress}&chainId=137`;

  return (
    <div className="token-address-header">
      <Button
        variant="outlined"
        size="small"
        color="secondary"
        href={polygonUrl}
        target="_blank"
        rel="noreferrer"
        sx={{ height: "18px", fontSize: "12px", marginLeft: "20px" }}
      >
        View Giddy Contract
      </Button>
      <Button
        variant="outlined"
        size="small"
        color="secondary"
        href={exchangeUrl}
        target="_blank"
        rel="noreferrer"
        sx={{ height: "18px", fontSize: "12px", marginLeft: "20px" }}
      >
        Buy Giddy
      </Button>
    </div>
  );
}
