import { BigNumber } from "ethers";

export enum WalletType {
  None = 'None',
  MetaMask = 'MetaMask',
  WalletConnect = 'WalletConnect',
  Coinbase = 'Coinbase',
}

export interface FarmInfo {
  readonly index: number,
  readonly pid: number,
  readonly symbol: string,
  readonly name: string,
  readonly address: string,
  readonly decimals: 18,
  readonly points: number,
}

export interface FarmPriceData {
  price: BigNumber,
  liquidity: number,
}

export interface FarmUserData {
  apr: BigNumber,
  staked: BigNumber,
  rewards: BigNumber,
}

export interface EthereumChain {
  chainId: string;
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: 18;
  };
  rpcUrls: string[];
  blockExplorerUrls?: string[];
  iconUrls?: string[];
}

export interface PriceData {
  prices: {
    gas: {
      fastGasPrice: string,
      proposeGasPrice: string,
      safeGasPrice: string,
      usdPrice: string,
    },
    tokens: TokenPrice[];
  }
}

export interface TokenPrice {
  price: string;
  symbol: string;
}