import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';

import { giddyTheme } from './code/GiddyTheme';
import { client } from './code/graphql';
import Routes from './components/Routes';
import ErrorComponent from './components/ErrorComponent';
import './fonts.css'
import './index.scss';

ReactDOM.render(
  <ErrorBoundary fallback={<ErrorComponent />}>
    <ApolloProvider client={client}>
      <ThemeProvider theme={giddyTheme}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  </ErrorBoundary>
  , document.getElementById("root")
);