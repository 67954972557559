import { BigNumber, ethers } from "ethers";
import { appConfig } from "./appConfig";
import Erc20Abi from './Erc20Abi.json';

const provider = new ethers.providers.JsonRpcProvider(appConfig.rpcUrl);

export async function getErc20TokenBalance(token: string, account: string): Promise<BigNumber> {
  try {
    const contract = new ethers.Contract(token, Erc20Abi.result, provider);
    const result = await contract.balanceOf(account);
    return result as BigNumber;
  }
  catch (error) {
    console.error('getErc20TokenBalance', error);
  }
  return ethers.constants.Zero;
}

export async function getErc20TokenAllowance(token: string, account: string, spender: string): Promise<BigNumber> {
  try {
    const contract = new ethers.Contract(token, Erc20Abi.result, provider);
    const result = await contract.allowance(account, spender);
    return result as BigNumber;
  }
  catch (error) {
    console.error('getErc20TokenAllowance', error);
  }
  return ethers.constants.Zero;
}