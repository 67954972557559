import { Button } from '@mui/material';
import React from 'react';
import './Footer.scss';
import GiddyLogo from '../content/giddy-horizontal-white.svg';
import IconTwitter from '../content/icon-twitter.svg';
import IconFacebook from '../content/icon-facebook.svg';
import IconLinkedin from '../content/icon-linkedin.svg';
import IconReddit from '../content/icon-reddit.svg';
import IconInstagram from '../content/icon-instagram.svg';
import IconDiscord from '../content/icon-discord.svg';
import IconTikTok from '../content/icon-tiktok.svg';

export default function Footer() {
  function giddyHomeClick() {
    window.open('https://giddy.co', '_blank');
  }

  function giddyLitepaperClick() {
    window.open('https://giddy.co/giddy-coin-litepaper', '_blank');
  }

  function giddyFAQClick() {
    window.open('https://giddy.co/faq', '_blank');
  }

  return (
    <div className="footer">
      <div className="footer-grid">
        <div className='footer-pages'>
          <Button variant="text" color="secondary" onClick={giddyHomeClick} sx={{fontSize: 14}}>Giddy Home</Button>
          <Button variant="text" color="secondary" onClick={giddyLitepaperClick} sx={{fontSize: 14}}>Litepaper</Button>
          <Button variant="text" color="secondary" onClick={giddyFAQClick} sx={{fontSize: 14}}>FAQ</Button>
        </div>
        <div className="footer-socials">
          <a href="https://twitter.com/giddydefi" target="_blank" rel="noreferrer"><IconTwitter /></a>
          <a href="https://www.facebook.com/Giddy-103231832213996" target="_blank" rel="noreferrer"><IconFacebook /></a>
          <a href="https://www.linkedin.com/company/giddydefi/mycompany/" target="_blank" rel="noreferrer"><IconLinkedin /></a>
          <a href="https://www.reddit.com/r/giddydefi/" target="_blank" rel="noreferrer"><IconReddit /></a>
          <a href="https://www.instagram.com/giddydefi/" target="_blank" rel="noreferrer"><IconInstagram /></a>
          <a href="https://discord.gg/9C46R5vSAP" target="_blank" rel="noreferrer"><IconDiscord /></a>
          <a href="https://www.tiktok.com/@giddydefi" target="_blank" rel="noreferrer"><IconTikTok /></a>
        </div>
        <div className="footer-logo">
          <a href="https://giddy.co/"><GiddyLogo /></a>
        </div>
        <div className="footer-copyright">© 2022 Giddy. All rights reserved</div>
      </div>
    </div>
  );
}