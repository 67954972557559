import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { init, setUser } from '@sentry/react';
import { BrowserTracing } from "@sentry/tracing";
import useRoutingInstrumentation from 'react-router-v6-instrumentation';
import App from './App';


export default function RoutesComponent() {
    const routingInstrumentation = useRoutingInstrumentation();

    useEffect(() => {
        init({
            dsn: "https://3820f87f04804675a9ac1c51a159605e@o1086015.ingest.sentry.io/6297652",
            integrations: [new BrowserTracing({
                tracingOrigins: ['localhost', 'coinlaunch.giddystaging.com', 'coinlaunch.giddy.co'],
                routingInstrumentation,
            })],
            tracesSampleRate: process.env.NODE_ENV === 'development' ? 1.0 : 0.2,
            environment: process.env.TARGET_ENV || process.env.NODE_ENV,
            release: process.env.SENTRY_RELEASE
        });

        setUser({
            ip_address: "{{auto}}"
        })
    }, [routingInstrumentation])

    return (
        <Routes>
            <Route path="/" element={<App />} />
        </Routes>
    );
}
