import { LinearProgress } from '@mui/material';
import { BigNumber, ethers } from 'ethers';
import React, { useEffect, useRef } from 'react';
import { formatTokenMoney, formatTokenValue } from '../code/globals';
import './FormatToken.scss';

interface FormatTokenProps {
  value: BigNumber,
  pending?: boolean,
  price?: BigNumber,
  decimals?: number,
  abbreviate?: boolean,
  showValue?: boolean,
  noRed?: boolean,
  fontSize?: any,
}

export default function FormatToken({ value, pending, price, decimals, abbreviate, showValue, noRed, fontSize }: FormatTokenProps) {
  if (!decimals) decimals = 18;
  if (!fontSize) fontSize = '20px';
  const valueElement = useRef<HTMLDivElement>(null);
  const subvalueElement = useRef<HTMLDivElement>(null);
  const lastValue = useRef(ethers.constants.MaxUint256);

  useEffect(() => {
    if (!value.eq(ethers.constants.MaxUint256)) {
      if (!lastValue.current.eq(ethers.constants.MaxUint256) && !lastValue.current.eq(value)) {
        if (valueElement.current) {
          valueElement.current.classList.remove('flash-red');
          valueElement.current.classList.remove('flash-green');
          void valueElement.current.offsetWidth;
          if (value.gt(lastValue.current)) {
            valueElement.current.classList.add('flash-green');
          }
          else if (!noRed) {
            valueElement.current.classList.add('flash-red');
          }
        }
        if (subvalueElement.current) {
          subvalueElement.current.classList.remove('flash-sub-red');
          subvalueElement.current.classList.remove('flash-sub-green');
          void subvalueElement.current.offsetWidth;
          if (value.gt(lastValue.current)) {
            subvalueElement.current.classList.add('flash-sub-green');
          }
          else if (!noRed) {
            subvalueElement.current.classList.add('flash-sub-red');
          }
        }
      }
      lastValue.current = value;
    }
  },[value, noRed]);

  if (pending) {
    return <LinearProgress sx={{width: 40}} color="inherit" />;
  }
  else if (price && showValue) {
    return (
      <div className="format-token" style={{fontSize}} ref={valueElement}>
        { formatTokenValue(value, decimals) } &nbsp;
        <span className='format-token-subvalue' ref={subvalueElement}>
          { formatTokenMoney(value, price, decimals, abbreviate) }
        </span>
      </div>
    ); 
  }
  else if (price) {
    return (
      <div className="format-token" style={{fontSize}} ref={valueElement}>
        { formatTokenMoney(value, price, decimals, abbreviate) }
      </div>
    );
  }
  else {
    return (
      <div className="format-token" style={{fontSize}} ref={valueElement}>
        { formatTokenValue(value, decimals) }
      </div>
    );
  }
}