import { BigNumber, ethers } from "ethers";

export function parseBigNumber(value: string, decimals: number): BigNumber {
  try {
    return ethers.utils.parseUnits(value, decimals);
  }
  catch {
    return BigNumber.from(0);
   }
}

export function getTokenMoney(value: BigNumber, price: BigNumber, decimals: number): number {
  try {
    return +ethers.utils.formatUnits(value.mul(price), decimals + 8);
  }
  catch {
    return 0;
  }
}

export function formatValue(value: number): string {
  try {
    if (value < 0.001) {
      const floored = Math.floor(value * 1e18) / 1e18;
      return BigNumber.from(floored).toString();
    }
    if (value < 1) {
      const floored = Math.floor(value * 1e4) / 1e4;
      return floored.toString();
    }
    const floored = Math.floor(value * 1e2) / 1e2;
    return floored.toLocaleString();
  }
  catch {
    return '';
  }
}

export function formatMoney(value: number, abbreviate = false): string {
  try {
    const prefix = value < 0 ? '-$' : '$';
    value = Math.abs(value);
    if (abbreviate) {
      if (value >= 1000) {
        if (value >= 1000000) {
          if (value >= 1000000000) {
            return prefix + (Math.round((value / 1000000000) * 1e1) / 1e1) + 'B';
          }
          return prefix + (Math.round((value / 1000000) * 1e1) / 1e1) + 'M';
        }
        return prefix + (Math.round((value / 1000) * 1e1) / 1e1) + 'K';
      }
    }
    if (value < 1e-6 || isNaN(value)) {
      return '$0';
    }
    for (let i = 0; i < 7; i++) {
      const power = Math.pow(10, i);
      if (value < 1e-5 * power) {
        const rounded = Math.round(value * (1e9 / power)) / (1e9 / power);
        return prefix + rounded.toString();
      }
    }
    const rounded = Math.round(value * 1e2) / 1e2;
    return prefix + rounded.toLocaleString(undefined, { minimumFractionDigits: 2 });
  }
  catch { }
  return '';
}

export function formatTokenValue(value: BigNumber, decimals: number, truncate = true): string {
  if (value.eq(ethers.constants.MaxUint256)) return '-';
  const stringValue = ethers.utils.formatUnits(value, decimals);
  const numberValue = +stringValue;
  if (Math.abs(numberValue) >= 1) {
    return (Math.floor(numberValue * 1e2) / 1e2).toLocaleString();
  }
  let count = 0;
  const formated = Array.from(stringValue).map((value) => { 
    if (count !== 0 || (value !== '0' && value !== '.' && value !== '-')) {
      count++;
    }
    if (count < 5) {
      return value;
    }
  }).join("");
  return truncate ? formated : stringValue;
}

export function formatTokenMoney(value: BigNumber, price: BigNumber, decimals: number, abbreviate = false): string {
  if (value.eq(ethers.constants.MaxUint256) || price.eq(ethers.constants.MaxUint256)) return '-';
  return formatMoney(getTokenMoney(value, price, decimals), abbreviate);
}