import React, { useEffect, useState } from 'react';
import { withProfiler, setUser as setSentryUser } from '@sentry/react';
import './App.scss';
import { BigNumber, ethers } from 'ethers';
import { FarmUserData, FarmInfo, PriceData as PricesData, WalletType, FarmPriceData } from '../code/types';
import ConnectDiaglog from './ConnectDialog';
import FarmDialog from './FarmDialog';
import { GiddyChef } from '../code/GiddyChef';
import { appConfig, getSymbolList, totalFarmPoints } from '../code/appConfig';
import { PRICES_QUERY } from '../code/graphql';
import { useQuery } from '@apollo/client';
import { formatMoney, formatTokenMoney, formatTokenValue, getTokenMoney } from '../code/globals';
import { getErc20TokenBalance } from '../code/Erc20';
import { Alert, CircularProgress, Snackbar } from '@mui/material';
import Footer from './Footer';
import Background from './Background';
import ConnectionBar from './ConnectionBar';
import { LoadingButton } from '@mui/lab';
import GiddyLogoBlack from '../content/giddy-horizontal-black.svg';
import iconGiddy from '../content/icon-giddy-coin.svg?url';
import iconGiddyUsdc from '../content/icon-giddytoken-usdc.svg?url';
import iconAave from '../content/icon-aave.svg?url';
import iconBtc from '../content/icon-btc.svg?url';
import iconDai from '../content/icon-dai.svg?url';
import iconUsdc from '../content/icon-usdc.svg?url';
import iconMatic from '../content/icon-matic.svg?url';
import iconEth from '../content/icon-eth.svg?url';
import iconSushi from '../content/icon-sushi.svg?url';
import iconQi from '../content/icon-qi.svg?url';
import iconBifi from '../content/icon-bifi.svg?url';
import FormatToken from './FormatToken';
import TokenAddress from './TokenAddress';
import DownloadGiddy from './DownloadGiddy';
import AppStore from "../content/app-store.svg?url";
import GooglePlay from "../content/google-play.svg?url";

declare const window: any;
const giddyChef = new GiddyChef();

function App() {
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [successText, setSuccessText] = useState('');
  const [harvestText, setHarvestText] = useState('Harvest All');
  const [farmPriceData, setFarmPriceData] = useState(Array(appConfig.farms.length) as FarmPriceData[]);
  const [farmUserData, setFarmUserData] = useState(Array(appConfig.farms.length) as FarmUserData[]);
  const [farmBalanceData, setFarmBalanceData] = useState(Array(appConfig.farms.length) as BigNumber[]);
  const [connectOpen, setConnectOpen] = useState(false);
  const [walletType, setWalletName] = useState((localStorage.getItem('wallet') ?? WalletType.None) as WalletType);
  const [loadCount, setLoadCount] = useState(0);
  const [loadProgress, setLoadProgress] = useState(0);


  const [farmOpen, setFarmOpen] = useState(false);
  const [selectedFarm, setSelectedFarm] = useState({} as FarmInfo);

  const [chainId, setChainId] = useState('');
  const [account, setAccount] = useState('');
  const [giddyPrice, setGiddyPrice] = useState(ethers.constants.MaxUint256);

  const [tvl, setTvl] = useState('-');
  const [formatedPrice, setFormatedPrice] = useState('-');
  const [marketCap, setMarketCap] = useState('-');
  const [dilutedMarketCap, setDilutedMarketCap] = useState('-');
  const [userGiddy, setUserGiddy] = useState(ethers.constants.MaxUint256);

  const pricesQuery = useQuery<PricesData>(PRICES_QUERY, { 
    variables: { 
      input: { 
        symbols: getSymbolList()
      } 
    } 
  });

  useEffect(() => {
    if (window.ethereum?.on) {
      const handleAccountsChanged = (accounts: any) => {
        setAccount(accounts[0]);
        console.log('EVENT.accountChanged: ' + accounts[0]);
      };
      window.ethereum.on('accountsChanged', handleAccountsChanged);
      
      const handleChainChanged = (chainId: any) => {
        setChainId(chainId);
        console.log('EVENT.chainChanged: ' + chainId);
      };
      window.ethereum.on('chainChanged', handleChainChanged);

      return () => {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      }
    }
  },[]);

  useEffect(() => {
    if (walletType === WalletType.None) {
      giddyChef.disconnect();
      setChainId('');
      setAccount('');
    }
    else {
      giddyChef.connect(walletType).then((error) => {
        if (error) {
          displayError(error);
        }
        else {
          giddyChef.getChainId().then((value) => {
            setChainId(value);
            giddyChef.getAccount().then((value) => {
              setAccount(value);
              setSentryUser({ id: value });
            });
          });
        }
      });
    }
  }, [walletType]);

  useEffect(() => {
    const loadBalanceData = async () => {
      const promises: Array<Promise<BigNumber>> = Array(10);
      for (let i = 0; i < appConfig.farms.length; i++) {
        promises[i] = getErc20TokenBalance(appConfig.farms[i].address, appConfig.giddyChefAddress);
      }
      setFarmBalanceData(await Promise.all(promises));
    };
    loadBalanceData();
  }, [loadCount]);

  useEffect(() => {
    try {
      if (pricesQuery.error) {
        console.error('GIDDY.pricesQuery', pricesQuery.error);
      }
      const loadPriceData = async () => {
        try {
          if (pricesQuery.data) {
            if (pricesQuery.data.prices?.gas?.fastGasPrice) {
              GiddyChef.gasPrice = ethers.utils.parseUnits(pricesQuery.data.prices.gas.fastGasPrice, 'gwei');
            }
            if (pricesQuery.data.prices?.tokens) {
              let totalLiquidity = 0.0;
              const prices = Array(appConfig.farms.length) as FarmPriceData[];
              for (let i = 0; i < appConfig.farms.length; i++) {
                const data = { price: ethers.constants.Zero, liquidity: 0 } as FarmPriceData;
                for (let j = 0; j < pricesQuery.data.prices.tokens.length; j++) {
                  if (pricesQuery.data.prices.tokens[j].price) {
                    if (pricesQuery.data.prices.tokens[j].symbol === appConfig.farms[i].symbol) {
                      data.price = BigNumber.from(pricesQuery.data.prices.tokens[j].price);
                      const balance = (farmBalanceData[i] ?? ethers.constants.Zero);
                      data.liquidity = getTokenMoney(balance, data.price, appConfig.farms[i].decimals);
                      totalLiquidity += data.liquidity;
                    }
                  }
                }
                prices[i] = data;
              }
              setFarmPriceData(prices);
              setGiddyPrice(prices[0].price);
              setTvl(totalLiquidity > 0 ? formatMoney(totalLiquidity, true) : '-');
            }
          }
        }
        catch (error: any) {
          console.error('GIDDY.loadPriceData', error);
        }
      };
      loadPriceData();
    }
    catch (error: any) {
      console.error('GIDDY.pricesQuery', error);
    }
  }, [pricesQuery.loading, pricesQuery.error, pricesQuery.data, farmBalanceData]);

  useEffect(()=> {
    if (!giddyPrice.eq(ethers.constants.MaxUint256) && hoursLeft(appConfig.startTime) <= 0) {
      setFormatedPrice(formatTokenMoney(BigNumber.from(1), giddyPrice, 0));
      const currentTime = Date.now() / 1000;
      if (currentTime < appConfig.endTime) {
        const giddyPerSecond = +ethers.utils.formatUnits(appConfig.giddyPerSecond, 18);
        const givenAway = Math.round((currentTime - appConfig.startTime) * giddyPerSecond);
        setMarketCap(formatTokenMoney(BigNumber.from(430000000).add(givenAway), giddyPrice, 0, true));
      }
      else {
        setMarketCap(formatTokenMoney(BigNumber.from(1000000000), giddyPrice, 0, true));
      }
      setDilutedMarketCap(formatTokenMoney(BigNumber.from(1000000000), giddyPrice, 0, true));
    }
  }, [giddyPrice]);

  useEffect(() => {
    if (chainId !== appConfig.network.chainId || !account) {
      setUserGiddy(ethers.constants.MaxUint256);
      setFarmUserData(Array(appConfig.farms.length) as FarmUserData[]);
    }
    else {
      const loadUserData = async () => {
        try {
          const giddyFarm = appConfig.farms[0];
          setUserGiddy(await getErc20TokenBalance(giddyFarm.address, account));
  
          const dataList = Array(appConfig.farms.length) as FarmUserData[];
          const stakedList = await giddyChef.poolBalances(account);
          const rewardsList = await giddyChef.pendingGiddyForUser(account);
          for (let i = 0; i < appConfig.farms.length; i++)
          {
            const farm = appConfig.farms[i];
            if (!dataList[i]) {
              dataList[i] = { staked: ethers.constants.Zero, rewards: ethers.constants.Zero } as FarmUserData;
            }
            if (stakedList.length > farm.pid) dataList[i].staked = stakedList[farm.pid];
            if (rewardsList.length > farm.pid) dataList[i].rewards = rewardsList[farm.pid];
          }
          setFarmUserData(dataList);
        }
        catch (error: any) {
          console.error('GIDDY.loadUserData', error);
        }
      }
      loadUserData();
    }
  }, [loadCount, chainId, account]);

  useEffect(() => {
    if (hoursLeft(appConfig.startTime) <= 0) {
      let progress = 0;
      const interval = setInterval(() => {
        if (!account) {
          setLoadProgress(progress = 0);
        }
        else {
          setLoadProgress(progress += 6.6667);
          if (progress >= 100) {
            if (document.hasFocus()) {
              setLoadCount(loadCount => loadCount + 1);
            }
            progress = 0;
          }
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [account]);

  function walletSelected(wallet: WalletType) {
    if (wallet) {
      localStorage.setItem('wallet', wallet);
      setWalletName(wallet);
      setConnectOpen(false);
    }
  }

  function rowClicked(e: React.MouseEvent<HTMLTableRowElement>) {
    const index = parseInt(e.currentTarget.id);
    setSelectedFarm(appConfig.farms[index]);
    setFarmOpen(true);
  }

  async function harvestAllClicked() {
    if (account) {
      setHarvestText('Confirming');
      const harvestResult = await giddyChef.harvestAll();
      if (!ethers.utils.isHexString(harvestResult)) {
        displayError(harvestResult);
      }
      else {
        setHarvestText('Harvesting');
        const harvestSuccess = await giddyChef.waitForTransactionStatus(harvestResult, 1, 1000, 300);
        if (!harvestSuccess) {
          displayError('Harvest unsuccessful, transaction failed');
        }
        else {
          displaySuccess('Giddy Up! Successfully harvested all farms');
          setLoadCount(loadCount => loadCount + 1);
        }
      }
    }
    setHarvestText('Harvest All');
  }

  async function farmClosed(message: string) {
    setFarmOpen(false);
    if (message) {
      setLoadCount(loadUserCount => loadUserCount + 1);
      displaySuccess(message);
    }
  }

  function farmLiquidity(index: number): string {
    if (farmPriceData[index]) {
      return formatMoney(farmPriceData[index].liquidity, true);
    }
    return '-';
  }

  function farmRewardsPerDay(index: number): BigNumber {
    if (farmUserData[index] && farmBalanceData[index]) {
      const poolTotal = farmBalanceData[index];
      if (poolTotal.gt(0)) {
        const amount = farmUserData[index].staked;
        const farmPerDay = appConfig.giddyPerSecond.mul(86400).mul(appConfig.farms[index].points).div(totalFarmPoints);
        return farmPerDay.mul(amount).div(poolTotal);
      }
    }
    return ethers.constants.Zero;
  }

  function totalStakedPrice(): string {
    const total = appConfig.farms.reduce((totalPrice, _value, index) => {
      const staked = farmUserData[index]?.staked || 0
      const price = farmPriceData[index]?.price || 0
      const decimals = appConfig.farms[index]?.decimals || 18

      return totalPrice + getTokenMoney(staked, price, decimals)
    }, 0)

    return formatMoney(total, true)
  }
  
  function displayError(text: string) {
    setErrorText(text);
    setErrorOpen(true);
  }

  function displaySuccess(text: string) {
    setSuccessText(text);
    setSuccessOpen(true);
  }

  function iconLookup(symbol: string): any {
    switch(symbol) {
      default: return iconGiddy;
      case 'USDC': return iconUsdc;
      case 'DAI': return iconDai;
      case 'QI': return iconQi;
      case 'WMATIC': return iconMatic;
      case 'SUSHI': return iconSushi;
      case 'AAVE': return iconAave;
      case 'WBTC': return iconBtc;
      case 'WETH': return iconEth;
      case 'BIFI': return iconBifi;
      case 'GIDDY-FF-USDC': return iconGiddyUsdc;
      case 'GIDDY-USDC': return iconGiddyUsdc;
    }
  }

  function hoursLeft(date: number): number {
    const diffTime = date - Date.now() / 1000;
    if (diffTime < 0) return 0;
    const diffHours = Math.ceil(diffTime / (60 * 60)); 
    return diffHours;
  }

  function daysLeft(date: number): number {
    const diffTime = date - Date.now() / 1000;
    if (diffTime < 0) return 0;
    const diffDays = Math.ceil(diffTime / (60 * 60 * 24)); 
    return diffDays;
  }

  function getBanner() {
    if (hoursLeft(appConfig.startTime) > 0) {
      return (
        <h4 className="banner-header">
          Coin launches in <span className='big-number'>{hoursLeft(appConfig.startTime)}</span> hours! Get in early to start earning rewards on launch day.
        </h4>
      );
    }
    else {
      return (
        <h4 className="banner-header">
          Thanks for a great coin launch! Check out the Giddy mobile app for more earning opportunities. 
        </h4>
      );
    }
  }

  return (
    <div className="app">
      <div className="content">
        <TokenAddress />
        <div className="top-bar">
          <div className="top-bar-grid">
            <a href="https://giddy.co/"><GiddyLogoBlack /></a>
            <ConnectionBar
              onConnect={() => { setConnectOpen(true) }} 
              walletType={walletType}
              chainId={chainId}
              account={account} />
          </div>
        </div>
        <h1 className="main-header">Giddy Token Launch</h1>
        <h4>
          Coin launch completed! Check out the Giddy mobile app for more earning opportunities
        </h4>
        <div className="widget-box">
          <div className="widget-start">
            <div className="widget-header">TVL</div>
            <div className="widget-value">{tvl}</div>
          </div>
          <div className="widget">
            <div className="widget-header">Giddy Price</div>
            <div className="widget-value">{formatedPrice}</div>
          </div>
          <div className="widget">
            <div className="widget-header">Marketcap</div>
            <div className="widget-value">{marketCap}</div>
          </div>
          <div className="widget">
            <div className="widget-header">Fully Diluted Marketcap</div>
            <div className="widget-value">{dilutedMarketCap}</div>
          </div>
          <div className="widget">
            <div className="widget-header">Your Giddy Coin</div>
            <FormatToken value={userGiddy} price={giddyPrice} decimals={appConfig.farms[0].decimals} abbreviate={false} showValue={true} />
          </div>
        </div>
        <div className="farm-box">
          <div className="farm-box-top">
            <div className="farm-box-header">
              Farms
            </div>
            <div className="farm-box-harvest">
                <LoadingButton 
                  className="harvest" 
                  variant="outlined"
                  disabled={hoursLeft(appConfig.startTime) > 0}
                  loading={harvestText !== 'Harvest All'}
                  loadingPosition="end"
                  fullWidth
                  onClick={harvestAllClicked}>
                    {harvestText}
                </LoadingButton>
            </div>
          </div>
          <table>
            <tbody>
              <tr className="table-row-header">
                <th colSpan={2}>Name</th>
                <th style={{minWidth: 80}}>Liquidity</th>
                <th style={{minWidth: 190}}>My Stakes</th>
                <th style={{minWidth: 190}}>
                  <div className="rewards-box">
                    Rewards
                    <img className="rewards-icon" src={iconLookup(appConfig.farms[0].symbol)} alt={appConfig.farms[0].symbol} />
                  </div>
                </th>
              </tr>
              <tr className='promote-app-row'>
                <td>
                  <img className="symbol-icon" src={iconLookup(appConfig.farms[1].symbol)} alt={appConfig.farms[1].symbol} />
                </td>
                <td colSpan={4}>
                  <div className="promote-app">
                    Staking rewards continue in the Giddy mobile app. Download today!
                    <a
                      href="https://apps.apple.com/us/app/giddy-grow-your-crypto/id1596780905"
                      target="_blank"
                      rel="noreferrer"
                      data-device="iOS"
                    >
                      <img src={AppStore} alt="iOS App Download Button" />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.getdefiq.giddy"
                      target="_blank"
                      rel="noreferrer"
                      data-device="Android"
                    >
                      <img src={GooglePlay} alt="Android App Download Button" />
                    </a>
                  </div>
                </td>
              </tr>
              {
                appConfig.farms.map((farm, index) => {
                  return (
                    <tr id={index.toString()} key={index} onClick={rowClicked}>
                      <td className="symbol-icon-cell">
                        <img className="symbol-icon" src={iconLookup(farm.symbol)} alt={farm.symbol} />
                      </td>
                      <td className="symbol-cell">
                        {farm.symbol}
                      </td>
                      <td>{farmLiquidity(index)}</td>
                      <td>
                        <FormatToken 
                          showValue={true} 
                          value={farmUserData[index] ? farmUserData[index].staked : ethers.constants.Zero}
                          price={farmPriceData[index] ? farmPriceData[index].price : undefined} 
                          decimals={appConfig.farms[index].decimals} 
                          fontSize="14px" />
                      </td>
                      <td>
                        <FormatToken 
                          showValue={true} 
                          value={farmUserData[index] ? farmUserData[index].rewards : ethers.constants.Zero} 
                          price={farmPriceData[0] ? farmPriceData[0].price : undefined} 
                          decimals={appConfig.farms[0].decimals} 
                          fontSize="14px" 
                          noRed={true} />
                      </td>
                    </tr>
                  );
                })
              }
              <tr>
                <td colSpan={2} style={{textAlign:'right'}}>Totals</td>
                <td>
                    {totalStakedPrice()}
                </td>
                <td>
                  <FormatToken 
                    showValue={true}
                    value={ appConfig.farms.reduce((total, value) => total.add(farmRewardsPerDay(value.index)), BigNumber.from(0)) } 
                    price={farmPriceData[0] ? farmPriceData[0].price : undefined}
                    decimals={appConfig.farms[0].decimals}
                    fontSize="14px" />
                  </td>
                <td>
                  <FormatToken 
                    showValue={true}
                    value={ appConfig.farms.reduce((total, value) => total.add(farmUserData[value.index] ? farmUserData[value.index].rewards : ethers.constants.Zero), BigNumber.from(0)) }
                    price={farmPriceData[0] ? farmPriceData[0].price : undefined}
                    decimals={appConfig.farms[0].decimals}
                    fontSize="14px" 
                    noRed={true} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        { getBanner() }
      </div>
      <Footer />
      <ConnectDiaglog
        onClose={() => { setConnectOpen(false) }} 
        onSelect={walletSelected} 
        isOpen={connectOpen}
        walletType={walletType} />
      <FarmDialog 
        onClose={farmClosed} 
        isOpen={farmOpen} 
        farm={selectedFarm} 
        giddyChef={giddyChef} 
        account={account} />
      <Snackbar open={errorOpen} autoHideDuration={7000} anchorOrigin={{ vertical:'bottom', horizontal: 'center' }} onClose={() => {setErrorOpen(false)}}>
        <Alert onClose={() => {setErrorOpen(false)}} severity="error" sx={{ width: '100%' }}>
          {errorText}
        </Alert>
      </Snackbar>
      <Snackbar open={successOpen && !errorOpen} autoHideDuration={7000} anchorOrigin={{ vertical:'bottom', horizontal: 'center' }} onClose={() => {setSuccessOpen(false)}}>
        <Alert onClose={() => {setSuccessOpen(false)}} severity="success" sx={{ width: '100%' }}>
          {successText}
        </Alert>
      </Snackbar>
      <Background />
    </div>
  );
}

export default withProfiler(App);