import { BigNumber } from "ethers";
import { EthereumChain, FarmInfo } from "./types";

export const testConfig = {
  graphqlUrl: 'https://chefapi.giddystaging.com/g',
  rpcUrl: 'https://polygon-mumbai.infura.io/v3/e62586f4e0964dc89d82777a4dd7a1ab',
  giddyChefAddress: '0xeF813e969cB6A2be8820a5283fFf9D3083fFFdca',
  giddyPerSecond: BigNumber.from('18706404565600000000'),
  startTime: 1646265523,
  endTime: 1677801523,
  network: {
    chainId: '0x13881',
    chainName: 'Mumbai',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: [
      'https://polygon-rpc.com/',
      'https://rpc-mainnet.matic.network',
      'https://matic-mainnet.chainstacklabs.com',
      'https://rpc-mainnet.maticvigil.com',
      'https://rpc-mainnet.matic.quiknode.pro',
      'https://matic-mainnet-full-rpc.bwarelabs.com'],
    blockExplorerUrls: ['https://explorer-mainnet.maticvigil.com/']
  } as EthereumChain,
  farms: [
    { index: 0, pid: 0, symbol: 'GIDDY', name: 'Giddy Gold', address: '0x303728d50e73281124C22bDe83606A7Ce1e43C98', decimals: 18, points: 100 },
    { index: 1, pid: 1, symbol: 'USDC', name: 'USD Coin', address: '0xe6b8a5CF854791412c1f6EFC7CAf629f5Df1c747', decimals: 18, points: 20 },
  ] as FarmInfo[]
}

export const stagingConfig = {
  graphqlUrl: 'https://chefapi.giddystaging.com/g',
  rpcUrl: 'https://polygon-rpc.com/',
  giddyChefAddress: '0x1c65C0D42C1428C2624FE3Eff3a0b52aD86F5AA7',
  giddyPerSecond: BigNumber.from('18706404565600000000'),
  startTime: 1646265523,
  endTime: 1677801523,
  network: {
    chainId: '0x89',
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: [
      'https://polygon-rpc.com/',
      'https://rpc-mainnet.matic.network',
      'https://matic-mainnet.chainstacklabs.com',
      'https://rpc-mainnet.maticvigil.com',
      'https://rpc-mainnet.matic.quiknode.pro',
      'https://matic-mainnet-full-rpc.bwarelabs.com'],
    blockExplorerUrls: ['https://polygonscan.com/']
  } as EthereumChain,
  farms: [
    { index: 0, pid: 0, symbol: 'GIDDY-FF', name: 'FemtoFarthing', address: '0x55975321D9d22587d1742DC68B35481C8DBB0Db8', decimals: 18, points: 100 },
    { index: 1, pid: 9, symbol: 'GIDDY-FF-USDC', name: 'Femto Farthing USDC', address: '0xf777455fC8BBd4033b1E0dB1423B13BDF3B68694', decimals: 18, points: 400 },
    { index: 2, pid: 10, symbol: 'BIFI', name: 'Beefy Token', address: '0xFbdd194376de19a88118e84E279b977f165d01b8', decimals: 18, points: 20 },
    { index: 3, pid: 1, symbol: 'USDC', name: 'USD Coin', address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', decimals: 6, points: 10 },
    { index: 4, pid: 4, symbol: 'WMATIC', name: 'Wrapped Matic', address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270', decimals: 18, points: 10 },
    { index: 5, pid: 8, symbol: 'WETH', name: 'Wrapped Ether', address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', decimals: 18, points: 10 },
    { index: 6, pid: 7, symbol: 'WBTC', name: 'Wrapped BTC', address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6', decimals: 8, points: 10 },
    { index: 7, pid: 6, symbol: 'AAVE', name: 'Aave', address: '0xD6DF932A45C0f255f85145f286eA0b292B21C90B', decimals: 18, points: 10 },
    { index: 8, pid: 2, symbol: 'DAI', name: 'Dai Stablecoin', address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063', decimals: 18, points: 10 },
    { index: 9, pid: 5, symbol: 'SUSHI', name: 'Sushi Token', address: '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a', decimals: 18, points: 10 },
  ] as FarmInfo[]
}

export const prodConfig = {
  graphqlUrl: 'https://chefapi.giddy.co/g',
  rpcUrl: 'https://polygon-mainnet.infura.io/v3/e62586f4e0964dc89d82777a4dd7a1ab',
  giddyChefAddress: '0xd814b26554204245A30F8A42C289Af582421Bf04',
  giddyPerSecond: BigNumber.from('18072289156600000000'),
  startTime: 1649386800,
  endTime: 1680922800,
  network: {
    chainId: '0x89',
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: [
      'https://polygon-rpc.com/',
      'https://rpc-mainnet.matic.network',
      'https://matic-mainnet.chainstacklabs.com',
      'https://rpc-mainnet.maticvigil.com',
      'https://rpc-mainnet.matic.quiknode.pro',
      'https://matic-mainnet-full-rpc.bwarelabs.com'],
    blockExplorerUrls: ['https://polygonscan.com/']
  } as EthereumChain,
  farms: [
    { index: 0, pid: 0, symbol: 'GIDDY', name: 'Giddy Token', address: '0x67eB41A14C0fe5CD701FC9d5A3D6597A72F641a6', decimals: 18, points: 100 },
    { index: 1, pid: 8, symbol: 'GIDDY-USDC', name: 'Giddy USDC Liquidity Pool', address: '0xDE990994309BC08E57aca82B1A19170AD84323E8', decimals: 18, points: 400 },
    { index: 2, pid: 9, symbol: 'BIFI', name: 'Beefy Token', address: '0xFbdd194376de19a88118e84E279b977f165d01b8', decimals: 18, points: 20 },
    { index: 3, pid: 1, symbol: 'USDC', name: 'USD Coin', address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', decimals: 6, points: 10 },
    { index: 4, pid: 3, symbol: 'WMATIC', name: 'Wrapped Matic', address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270', decimals: 18, points: 10 },
    { index: 5, pid: 7, symbol: 'WETH', name: 'Wrapped Ether', address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', decimals: 18, points: 10 },
    { index: 6, pid: 6, symbol: 'WBTC', name: 'Wrapped BTC', address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6', decimals: 8, points: 10 },
    { index: 7, pid: 5, symbol: 'AAVE', name: 'Aave', address: '0xD6DF932A45C0f255f85145f286eA0b292B21C90B', decimals: 18, points: 10 },
    { index: 8, pid: 2, symbol: 'DAI', name: 'Dai Stablecoin', address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063', decimals: 18, points: 10 },
    { index: 9, pid: 4, symbol: 'SUSHI', name: 'Sushi Token', address: '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a', decimals: 18, points: 10 },
  ] as FarmInfo[]
}

export const appConfig = process.env.TARGET_ENV === 'production' ? prodConfig : prodConfig;

export const totalFarmPoints = appConfig.farms.reduce((total, value) => total + value.points, 0);

export function getSymbolList(): string[] {
  const ret = [] as string[];
  appConfig.farms.map((farm) => {
    ret.push(farm.symbol);
  });
  return ret;
}