import { Web3Provider, JsonRpcProvider, JsonRpcSigner } from '@ethersproject/providers';
import { BigNumber, BigNumberish, ethers } from 'ethers';
import { EthereumChain, WalletType } from './types';
import GiddyTokenAbi from '../code/GiddyTokenAbi.json';
import GiddyChefAbi from '../code/GiddyChefAbi.json';
import { appConfig, stagingConfig } from './appConfig';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';

declare const window: any;

export class GiddyChef {
  public static gasPrice: BigNumber = ethers.utils.parseUnits('55', 'gwei');
  public provider: JsonRpcProvider;
  public walletType: WalletType;
  private giddyChefContract: ethers.Contract;
  private walletProvider: Web3Provider | null;
  private signer: JsonRpcSigner | null;
  private giddyChefSigner: ethers.Contract | null;
 
  constructor() {
    this.provider = new ethers.providers.JsonRpcProvider(appConfig.rpcUrl);
    this.giddyChefContract = new ethers.Contract(appConfig.giddyChefAddress, GiddyChefAbi.result, this.provider);
    this.walletType = WalletType.None;
    this.walletProvider = null;
    this.signer = null;
    this.giddyChefSigner = null;
  }

  public async connect(walletType: WalletType): Promise<string> {
    try {
      this.walletType = walletType;
      switch(walletType) {
        default:
          {
            if (!window.ethereum) {
              return 'Install MetaMask browser extension';
            }
            this.walletProvider = new ethers.providers.Web3Provider(window.ethereum);
          }
          break;
        case WalletType.WalletConnect:
          {
            const walletConnectProvider = new WalletConnectProvider({ rpc: { 137: appConfig.network.rpcUrls[0] } });
            this.walletProvider = new ethers.providers.Web3Provider(walletConnectProvider);
  
            if (walletConnectProvider.isWalletConnect) {
              walletConnectProvider.disconnect().then(() => {
                walletConnectProvider.enable();
              }).catch (() => {
                walletConnectProvider.enable();
              });
            }
            else {
              walletConnectProvider.enable();
            }
          }
          break;
          case WalletType.Coinbase:
            {
              const coinbaseWallet = new WalletLinkConnector({
                url: appConfig.network.rpcUrls[0],
                appName: 'Giddy',
                supportedChainIds: [137],
              });
              await coinbaseWallet.activate();
              this.walletProvider = new ethers.providers.Web3Provider(await coinbaseWallet.getProvider());
            }
            break;
      }
      this.signer = this.walletProvider.getSigner();
      this.giddyChefSigner = new ethers.Contract(appConfig.giddyChefAddress, GiddyChefAbi.result, this.signer);
      
      const chainID = await this.getChainId();
      if (!chainID) {
        return 'Error retrieving network from wallet';
      }
      else {
        console.log('GIDDY: Wallet on network ' + chainID);
        if (chainID !== appConfig.network.chainId) {
          console.log('GIDDY: Wrong network switching')
          if (!await this.switchChain(appConfig.network.chainId)) {
            console.log('GIDDY: Failed to switch network')
            if (!await this.addChain(appConfig.network)) {
              return 'Error adding polygon network to wallet';
            }
            else {
              console.log('GIDDY: Added network ' + appConfig.network.chainId + ' to wallet');
            }
          }
          else {
            console.log('GIDDY: Switched network to ' + appConfig.network.chainId);
          }
        }
        return '';
      }
    }
    catch (error: any) {
      return error.message;
    }
  }

  public disconnect() {
    this.walletType = WalletType.None;
    this.walletProvider = null;
    this.signer = null;
    this.giddyChefSigner = null;
  }

  public async getChainId(): Promise<string> {
    try {
      if (this.walletProvider) {
        const result = await this.walletProvider.send('eth_chainId', []) as string;
        return ethers.utils.hexStripZeros(result);
      }
    }
    catch (error) {
      console.error('GIDDY.GiddyChef.getChainId', error);
    }
    return '';
  }

  public async getBlockNumber(): Promise<number> {
    try {
      return await this.provider.getBlockNumber()
    }
    catch (error) {
      console.error('GIDDY.GiddyChef.getBlockNumber', error);
    }
    return 0;
  }

  public async getAccount(): Promise<string> {
    try {
      if (this.walletType === WalletType.WalletConnect) {
        if (this.signer) {
          const result = await this.signer.getAddress();
          return result;
        }
      }
      if (this.walletProvider) {
        const result = await this.walletProvider.send('eth_requestAccounts', []) as string;
        return result[0];
      }
    }
    catch (error) {
      console.error('GIDDY.GiddyChef.getAccount', error);
    }
    return '';
  }

  public async switchChain(chainId: string): Promise<boolean> {
    try {
      if (this.walletProvider) {
        const error = await this.walletProvider.send('wallet_switchEthereumChain', [{chainId}]);
        if (error !== null) {
          console.error('GIDDY.GiddyChef.switchChain.result', error);
        }
        else {
          return true;
        }
      }
    }
    catch (error) {
      console.error('GIDDY.GiddyChef.switchChain', error);
    }
    return false;
  }

  public async addChain(chain: EthereumChain): Promise<boolean> {
    try {
      if (this.walletProvider) {
        const error = await this.walletProvider.send('wallet_addEthereumChain', [chain]);
        if (error !== null) {
          console.error('GIDDY.GiddyChef.addChain.result', error);
        }
        else {
          return true;
        }
      }
    }
    catch (error) {
      console.error('GIDDY.GiddyChef.addChain', error);
    }
    return false;
  }

  public async waitForTransactionStatus(hash: string, confirms: number, waitTime: number, retries: number): Promise<boolean> {
    try {
      for (let i = 0; i < retries + 1; i++) {
        try { await this.provider.waitForTransaction(hash, confirms, waitTime === -1 ? undefined : waitTime); } catch {}
        const receipt = await this.provider.getTransactionReceipt(hash);
        if (receipt) {
          return receipt.status === 1;
        }
      }
    }
    catch (error: any) {
      console.error('GIDDY.GiddyChef.waitForTransactionStatus', error);
    }
    return false;
  }

  public async approve(token: string, amount: BigNumber): Promise<string> {
    try {
      if (!this.signer) return 'Not Connected';
      const contract = new ethers.Contract(token, GiddyTokenAbi.result, this.signer);
      const gasEsitmate = await contract.estimateGas.approve(appConfig.giddyChefAddress, amount);
      const options = { gasLimit: gasEsitmate };
      const result = await contract.approve(appConfig.giddyChefAddress, amount, options);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.GiddyChef.approve', error);
      return error.message;
    }
  }

  public async giddySignature(amount: BigNumber, currentApproval: BigNumber) {
    try {
      const message = {
        owner: await this.signer?.getAddress(),
        spender: appConfig.giddyChefAddress,
        value: amount.toString(),
        deadline: (Math.floor(Date.now() / 1000) + 500).toString(),
        nonce: ethers.utils.hexlify(ethers.utils.randomBytes(32)),
        currentApproval: currentApproval.toString(),
      };

      if (appConfig.farms[0].name === stagingConfig.farms[0].name) {
        message.currentApproval = '0'
      }

      const data = {
        types: {
          EIP712Domain: [
            { name: "name", type: "string" },
            { name: "version", type: "string" },
            { name: "chainId", type: "uint256" },
            { name: "verifyingContract", type: "address" },
          ],
          ApproveWithAuthorization: [
            { name: "owner", type: "address" },
            { name: "spender", type: "address" },
            { name: "value", type: "uint256" },
            { name: "deadline", type: "uint256" },
            { name: "nonce", type: "bytes32" },
            { name: "currentApproval", type: "uint256" },
          ],
        },
        domain: {
          name: appConfig.farms[0].name,
          version: "1.0",
          verifyingContract: appConfig.farms[0].address,
          chainId: appConfig.network.chainId,
        },
        primaryType: "ApproveWithAuthorization",
        message: message,
      };

      const params = [
        message.owner,
        JSON.stringify(data),
      ]

      const signature = await window.ethereum.request({method: "eth_signTypedData_v4", params, from: message.owner})

      const approvalRequest = {
        owner: message.owner,
        spender: message.spender,
        value: message.value,
        deadline: message.deadline,
        nonce: message.nonce,
        currentApproval: message.currentApproval,
      };
    
      return ['', signature, approvalRequest];
    }
    catch (error: any) {
      console.error('GIDDY.GiddyChef.giddySignature', error);
      return [error.message, null, null];
    }
  }

  public async deposit(pid: BigNumberish, amount: BigNumber, signature: any, approvalRequest: any): Promise<string> {
    try {
      if (!this.giddyChefSigner) return 'Not Connected';

      if (pid === 0 && signature && approvalRequest) {
        approvalRequest.value = amount;
        const gasEsitmate = await this.giddyChefSigner.estimateGas.depositGiddy(pid, approvalRequest, signature);
        const options = { gasPrice: GiddyChef.gasPrice, gasLimit: gasEsitmate.mul(13).div(10) };
        const result = await this.giddyChefSigner.depositGiddy(pid, approvalRequest, signature, options);
        return result.hash;
      }
      else {
        const gasEsitmate = await this.giddyChefSigner.estimateGas.deposit(pid, amount);
        const options = { gasPrice: GiddyChef.gasPrice, gasLimit: gasEsitmate.mul(13).div(10) };
        const result = await this.giddyChefSigner.deposit(pid, amount, options);
        return result.hash;
      }
    }
    catch (error: any) {
      console.error('GIDDY.GiddyChef.deposit', error);
      return error.message;
    }
  }

  public async withdraw(pid: BigNumberish, amount: BigNumber): Promise<string> {
    try {
      if (!this.giddyChefSigner) return 'Not Connected';
      const gasEsitmate = await this.giddyChefSigner.estimateGas.withdraw(pid, amount);
      const options = { gasPrice: GiddyChef.gasPrice, gasLimit: gasEsitmate.mul(13).div(10) };
      const result = await this.giddyChefSigner.withdraw(pid, amount, options);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.GiddyChef.withdraw', error);
      return error.message;
    }
  }

  public async harvestAll(): Promise<string> {
    try {
      if (!this.giddyChefSigner) return 'Not Connected';
      const gasEsitmate = await this.giddyChefSigner.estimateGas.harvestAll();
      const options = { gasPrice: GiddyChef.gasPrice, gasLimit: gasEsitmate.mul(13).div(10) };
      const result = await this.giddyChefSigner.harvestAll(options);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.GiddyChef.harvestAll', error);
      return error.message;
    }
  }

  public async pendingGiddy(pid: BigNumberish, account: string): Promise<BigNumber> {
    try {
      return await this.giddyChefContract.pendingGiddy(pid, account);
    }
    catch (error: any) {
      console.error('GIDDY.GiddyChef.pendingGiddy', error);
    }
    return ethers.constants.Zero;
  }

  public async pendingGiddyForUser(account: string): Promise<BigNumber[]> {
    try {
      return await this.giddyChefContract.pendingGiddyForUser(account);
    }
    catch (error: any) {
      console.error('GIDDY.GiddyChef.pendingGiddyForUser', error);
    }
    return [];
  }

  public async stakedGiddy(pid: BigNumberish, account: string): Promise<BigNumber> {
    try {
      return (await this.giddyChefContract.userInfo(pid, account))[0];
    }
    catch (error: any) {
      console.error('GIDDY.GiddyChef.stakedGiddy', error);
    }
    return ethers.constants.Zero;
  }

  public async poolBalances(account: string): Promise<BigNumber[]> {
    try {
      return await this.giddyChefContract.poolBalances(account);
    }
    catch (error: any) {
      console.error('GIDDY.GiddyChef.poolBalances', error);
    }
    return [];
  }

  public async giddyPerSecond(): Promise<BigNumber> {
    try {
      return (await this.giddyChefContract.giddyPerSecond())[0];
    }
    catch (error: any) {
      console.error('GIDDY.GiddyChef.stakedGiddy', error);
    }
    return ethers.constants.Zero;
  }
}